@import '../../../styles/variables.scss';

.fullWidthTemplate {
    .header {
        width: 100%;
    }

    > main {
        width: 100%;
        overflow: auto;
    }

    .contactUs {
        background-color: $background-secondary-colour;
        border-top: 1px solid #444;
        border-bottom: 1px solid #444;
        width: 100%;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .whyUs {
        width: 100%;
        padding-bottom: 10px;
    }

    .footer {
        width: 100%;
    }

    @include respond-to('large') {
        .whyUs {
            padding: 0 0 20px;
        }
    }
}
