.tile {
    height: 200px;
    overflow: hidden;

    a {
        width: 100%;
        height: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        h3 {
            width: 100%;
            z-index: 1;
            padding: 10px 20px;
            font-weight: normal;
            text-align: center;
            background-color: rgba(0, 0, 0, 0.5);
            color: #fff;
        }

        .backgroundImage {
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 0;
            left: 0;
            top: 0;
        }
    }
}
