.faqs {
    h2 {
        text-align: center;
    }

    .questions {
        padding: 0;
        margin: 0;

        li {
            list-style: none;
        }
    }
}
