.home {
    .links,
    .content,
    .faqs {
        padding: 0 20px;
        margin-left: auto;
        margin-right: auto;
        max-width: 1200px;
        margin-bottom: 20px;

        h2 {
            text-align: center;
        }
    }
}
