@import '../../styles/variables.scss';

.tiledList {
    > h2 {
        text-align: center;
    }

    .tiles {
        list-style-type: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-flow: column nowrap;

        .tile {
            margin-bottom: 10px;
        }
    }

    @include respond-to('small') {
        .tiles {
            flex-flow: row wrap;
            .tile {
                width: calc(50% - 5px);
                &:nth-child(2n) {
                    margin-left: 10px;
                }
            }
        }
    }

    @include respond-to('medium') {
        .tiles {
            .tile {
                width: calc((100% - 20px) / 3);
                &:nth-child(3n + 1) {
                    margin-left: 0;
                }
                &:nth-child(3n + 2),
                &:nth-child(3n + 3) {
                    margin-left: 10px;
                }
            }
        }
    }

    @include respond-to('large') {
        .tiles {
            .tile {
                width: calc((100% - 30px) / 4);
                &:nth-child(4n + 1) {
                    margin-left: 0;
                }
                &:nth-child(4n + 2),
                &:nth-child(4n + 3),
                &:nth-child(4n + 4) {
                    margin-left: 10px;
                }
            }
        }
    }
}
